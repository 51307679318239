const chapData = {
  FE1: {
    Main: [
      "01-MarthEmbarks",
      "02-ThePiratesOfGalder",
      "03-ABrushInTheTeeth",
      "04-BattleInTheLea",
      "05-ChampionsOfAurelis",
      "06-FireEmblem",
      "07-LefcandithGauntlet",
      "08-PortWarren",
      "09-ThePyrathiDragon",
      "10-PrincessMinerva",
      "11-KnordaMarket",
      "12-TheAgelessPalace",
      "13-TheWoodenCavalry",
      "14-LandOfSorrow",
      "15-AnOasisOfMagic",
      "16-TheBattleForAltea",
      "17-StarAndSavior",
      "18-TheSableOrder",
      "19-ManaketePrincess",
      "20-CamusTheSable",
      "21-ClashInMacedon",
      "22-AKnightFilledSky",
      "23-DarkPontifex",
      "24-TheDragonkinRealm",
      "25-Endgame:ChosenByFate",
      "XX-Epilogue",
    ],
  },
  FE2: {
    Main: [
      "01-Zofia'sCall",
      "02-ThePilgrimage",
      "03-WarOfDeliverance",
      "04-LandOfSorrow",
      "05-TogetherToTheEnd",
    ],
  },
  FE3: {
    BookOne: [
      "01-MarthEmbarks",
      "02-ThePiratesOfGalder",
      "03-ABrushInTheTeeth",
      "04-ChampionsOfAurelis",
      "05-FireEmblem",
      "06-LefcandithGauntlet",
      "07-PortWarren",
      "08-PrincessMinerva",
      "09-KnordaMarket",
      "10-TheAgelessPalace",
      "11-LandOfSorrow",
      "12-AnOasisOfMagic",
      "13-TheBattleForAltea",
      "14-StarAndSavior",
      "15-ManaketePrincess",
      "16-CamusTheSable",
      "17-AKnightFilledSky",
      "18-DarkPontifex",
      "19-TheDragonkinRealm",
      "20-ChosenByFate",
    ],
    BookTwo: [
      "01-TheGrustianExpedition",
      "02-RebellionAtMacedon",
      "03-TheAbductedPrincess",
      "04-JoyAndSorrow",
      "05-LiberationOfGrust",
      "06-TheNestOfVice",
      "07-TheScarletSwordsman",
      "08-SoulfulBridge",
      "09-SanctuaryOfSorcery",
      "10-TwoSorcerors",
      "11-Anri'sWay",
      "12-GraveyardOfFireDragons",
      "13-FrozenLand",
      "14-AMysteryRevealed",
      "15-ReturnOfThePrince",
      "16-ReclaimingTheCapital",
      "17-Gra'sSunSets",
      "18-BattleOfThePass",
      "19-TheFinalBattle",
      "20-DarkEmperor",
      "21-TheWyvern'sDale",
      "22-TheDragon'sAltar1",
      "22-TheDragon'sAltar2",
      "22-TheDragon'sAltar3",
    ],
  },
  FE4: {
    Main: [
      "00-PrologueBirthOfACrusader",
      "01-TheSpiritForestsMaiden",
      "02-CrisisInAgustria",
      "03-EldiganTheLionheart",
      "04-DanceInTheSkies",
      "05-ThresholdOfFate",
      "06-HeirsOfLight",
      "07-BeyondTheDesert",
      "08-TheDracoknightsOfThracia",
      "09-ForWhoseSake",
      "10-LightAndDarkness",
      "11-EndgameTheFinalHolyWar",
      "XX-Epilogue",
    ],
  },
  FE5: {
    Main: [
      "01-TheWarriorOfFiana",
      "02-TheVillageOfIz",
      "02x-ThePirateIsles",
      "03-TheGateOfKelves",
      "04-TheDungeon",
      "04x-TheHeroOfWinds",
      "05-MotherAndChild",
      "06-TheEscape",
      "07-TheShieldOfThracia",
      "08-MountVioldrake",
      "08x-Dagdar'sMansion",
      "09-TheEmblemOfNjorun",
      "10-NoelCanyon",
      "11-DandrumFortress",
      "11x-Ballistrariae",
      "12-TheThievesofDakia",
      "12x-Dandelion",
      "13-TheStronghold",
      "14-OpenFire",
      "14x-Freedom",
      "15-TheTwoPaths",
      "16A-NordenLine",
      "16B-TheDarkForest",
      "17A-Leonster'sGate",
      "17B-TheFirstRainOfMay",
      "18-TheLiberationOfLeonster",
      "19-RemorseAndRetaliation",
      "20-ChildOfLight",
      "21-TheLiberationWars",
      "21x-DetentionCenter",
      "22-AcrossTheRiver",
      "23-ThePalaceOfEvil",
      "24-TheBaronInBlack",
      "24x-TheAltarOfLoptous",
      "25-AnUndyingOath",
    ],
  },
  FE6: {
    Main: [
      "01-DawnOfDestiny",
      "02-ThePrincessOfBern",
      "03-LateArrival",
      "04-CollapseOfTheAlliance",
      "05-TheEmblemOfFire",
      "06-TheTrap",
      "07-TheRebellionOfOstia",
      "08-Reunion",
      "08x-TheBlazingSword",
      "09-TheMistyIsles",
      "10A-TheResistanceForces",
      "10B-CaughtInTheMiddle",
      "11A-TheHeroOfTheWesternIsles",
      "11B-EscapeToFreedom",
      "12-TheTrueEnemy",
      "12x-TheAxeOfThunder",
      "13-RescueMission",
      "14-Arcadia",
      "14x-TheInfernalElement",
      "15-TheDragonGirl",
      "16-RetakingTheCapital",
      "16x-ThePinnacleOfLight",
      "17A-ThePathThroughTheOcean",
      "17B-ThePathThroughTheOcean",
      "18A-TheFrozenRiver",
      "18B-TheLawOfSacae",
      "19A-BitterCold",
      "19B-BattleInBulgar",
      "20A-TheLiberationOfIlia",
      "20Ax-TheFrozenSpear",
      "20B-TheSilverWolf",
      "20Bx-TheBowOfTheWinds",
      "21-TheBindingBlade",
      "21x-TheSilencingDarkness",
      "22-TheNeverendingDream",
      "23-TheGhostsOfBern",
      "24-TheTruthOfTheLegend",
      "25-BeyondTheDarkness",
    ],
  },
  FE7: {
    Main: [
      "00-AGirlFromThePlains",
      "01-FootstepsOfFate",
      "02-SwordOfSpirits",
      "03-BandOfMercenaries",
      "04-InOccupation'sShadow",
      "05-BeyondTheBorders",
      "06-BloodOfPride",
      "07-SiblingsAbroad",
      "07x-TheBlackShadow",
      "08-VortexOfStrategy",
      "09-AGrimReunion",
      "10-TheDistantPlains",
      "11-TakingLeave",
      "11H-AnotherJourney",
      "12-BirdsOfAFeather",
      "13-InSearchOfTruth",
      "13x-ThePeddlerMerlinus",
      "14-FalseFriends",
      "15-NobleLadyOfCaelin",
      "15H-TalonsAlight",
      "16-WhereaboutsUnknown",
      "16x-ThePortOfBadon",
      "17-PirateShip",
      "18-TheDreadIsle",
      "18x-ImprisonerOfMagic",
      "19-Dragon'sGate",
      "19Hx-AGlimpseInTime",
      "20-NewResolve",
      "21-Kinship'sBond",
      "22-LivingLegend",
      "22x-Genesis",
      "23-FourFangedOffense",
      "24-UnfulfilledHeart",
      "25-PaleFlowerOfDarkness",
      "25H-CrazedBeast",
      "26-BattleBeforeDawn",
      "27-CogOfDestiny",
      "27-NightOfFarewells",
      "28-ValorousRoland",
      "29-SandsOfTime",
      "29x-BattlePreparations",
      "30-VictoryOrDeath",
      "30H-TheBerserker",
      "31-Light",
      "32x-TheValueOfLife",
      "XX-Epilogue",
    ],
  },
  FE8: {
    Main: [
      "00-TheFallOfRenais",
      "01-Escape",
      "02-TheProtected",
      "03-TheBanditsOfBorgo",
      "04-AncientHorrors",
      "05-TheEmpire'sReach",
      "05x-UnbrokenHeart",
      "06-VictimsOfWar",
      "07-WatersideRenvall",
      "08-It'sATrap",
      "08x-ANewJourney",
      "09A-DistantBlade",
      "09B-FortRigwald",
      "10A-RevoltAtCarcino",
      "10B-TurningTraitor",
      "11A-CreepingDarkness",
      "11B-PhantomShip",
      "12A-VillageOfSilence",
      "12B-LandingAtTaizel",
      "13A-HamillCanyon",
      "13B-Fluorspar'sOath",
      "14A-QueenOfWhiteDunes",
      "14B-FatherAndSon",
      "15-ScorchedSand",
      "16-RuledByMadness",
      "17-RiverOfRegrets",
      "18-TrueFacesOfEvil",
      "19-LastHope",
      "20-DarklingWoods",
      "21-TheSacredStones",
      "XX-Epilogue",
    ],
  },
  FE9: {
    Main: [
      "00-Mercenaries",
      "01-TheBattleBegins",
      "02-Rescue",
      "03-PiratesAground",
      "04-RoadsideBattle",
      "05-Flight",
      "06-ABriefDiversion",
      "07-ShadesOfEvil",
      "08-DespairAndHope",
      "09-Gallia",
      "10-PrisonerRelease",
      "11-BloodRunsRed",
      "12-AStrangeLand",
      "13-AGuidingWind",
      "14-Training",
      "15-TheFeralFrontier",
      "16-TheAtonement",
      "17-DayBreaks",
      "18-CrimeaMarches",
      "19-Entrusted",
      "20-DefendingTalrega",
      "21-WithoutAKing",
      "22-Solo",
      "23-TheGreatBridge",
      "24-BattleReunion",
      "25-StrangeLands",
      "26-Clash",
      "27-MomentOfFate",
      "28-TwistedTower",
      "29-Endgame",
      "XX-Epilogue",
    ],
  },
  FE10: {
    Main: [
      "1:0-UnderGraySkies",
      "1:1-MaidenOfMiracles",
      "1:10-DaeinArise",
      "1:2-TheDispossessed",
      "1:3-AFaintLight",
      "1:4-ADistantVoice",
      "1:5-TheLostHeir",
      "1:6-RaiseTheStandard",
      "1:7-AGatheringHope",
      "1:8-GloryUnwanted",
      "1:9-OneSurvives",
      "2:0-OnDriftingClouds",
      "2:1-WindsOfRebellion",
      "2:2-TidesOfIntrigue",
      "2:3-Geoffrey'sCharge",
      "2:4-Elincia'sGambit",
      "3:0-TheGreatAdvance",
      "3:1-LaguzAndBeorc",
      "3:2-Stormclouds",
      "3:3-RiverCrossing",
      "3:4-TheGeneral'sHand",
      "3:5-Retreat",
      "3:6-AReasonToFight",
      "3:7-RivalsCollide",
      "3:8-IncandescentGlow",
      "3:9-Marauders",
      "3:10-TheHeartOfCrimea",
      "3:11-JustCause",
      "3:12-ThePrice",
      "3:13-BloodContract",
      "3:14-FromPain,Awakening",
      "4:0-ChaosNamed",
      "4:1-RoadToTheEmpire",
      "4:2-SilentWorld",
      "4:3-Distortions",
      "4:4-Revelations",
      "4:5-UnforgivableSin",
      "XX-Epilogue",
      "4:11-Rebirth1",
      "4:12-Rebirth2",
      "4:13-Rebirth3",
      "4:14-Rebirth4",
      "4:15-Rebirth5",
    ],
  },
  FE11: {
    Main: [
      "00-Prologue",
      "00-Prologue4",
      "01-MarthEmbarks",
      "02-ThePiratesOfGalder",
      "04-BattleInTheLea",
      "5-ChampionsOfAurelis",
      "06-FireEmblem",
      "07-LefcandithGauntlet",
      "08-PortWarren",
      "09-ThePyrathiDragon",
      "10-PrincessMinerva",
      "11-KnordaMarket",
      "12-TheAgelessPalace",
      "12x-ATraitor'sWorth",
      "13-TheWoodenCavalry",
      "14-LandOfSorrow",
      "15-AnOasisOfMagic",
      "16-TheBattleForAltea",
      "17-StarAndSavior",
      "17x-HelenaCastle",
      "18-TheSableOrder",
      "19-ManaketePrincess",
      "20-CamusTheSable",
      "20x-LairOfFire",
      "21-ClashInMacedon",
      "22-AKnightFilledSky",
      "23-DarkPontifex",
      "24x-TheAlterspire",
      "25-Endgame:ChosenByFate",
      "XX-Epilogue",
    ],
  },
  FE12: {
    BookOne: [
      "00-Prologue",
      "00-Prologue4",
      "01-MarthEmbarks",
      "02-ThePiratesOfGalder",
      "04-BattleInTheLea",
      "06-FireEmblem",
      "07-LefcandithGauntlet",
      "08-PortWarren",
      "10-PrincessMinerva",
      "11-KnordaMarket",
      "12-TheAgelessPalace",
      "12x-ATraitor'sWorth",
      "15-AnOasisOfMagic",
      "16-TheBattleForAltea",
      "17-StarAndSavior",
      "17x-HelenaCastle",
      "18-TheSableOrder",
      "19-ManaketePrincess",
      "20x-LairOfFire",
      "21-ClashInMacedon",
      "22-AKnightFilledSky",
      "23-DarkPontifex",
      "24x-TheAlterspire",
      "25-Endgame:ChosenByFate",
      "XX-Epilogue",
    ],
    BookTwo: [
      "00-Prologue1",
      "00-Prologue2",
      "00-Prologue3",
      "00-Prologue4",
      "00-Prologue5",
      "00-Prologue6",
      "00-Prologue7",
      "01-TheGrustianExpedition",
      "02-RebellionAtMacedon",
      "03-TheAbductedPrincess",
      "03x-ShadowsBehindTheScenes",
      "04-JoyAndSorrow",
      "05-LiberationOfGrust",
      "06-TheNestOfVice",
      "06x-MercenarySquad",
      "07-TheScarletSwordsman",
      "08-SoulfulBridge",
      "09-SanctuaryOfSorcery",
      "10-TwoSorcerors",
      "10x-TheMaskLaughs",
      "11-Anri'sWay",
      "12-GraveyardOfFireDragons",
      "13-FrozenLand",
      "13x-WithinTheWhiteDarkness",
      "14-AMysteryRevealed",
      "15-ReturnOfThePrince",
      "16-ReclaimingTheCapital",
      "16x-Reunion",
      "17-Gra'sSunSets",
      "18-BattleOfThePass",
      "19-TheFinalBattle",
      "20-DarkEmperor",
      "20x-DepthOfTheAbyss",
      "21-TheWyvern'sDale",
      "22-TheDragon'sAltar",
      "23-TheDarkPontifexLives",
      "24-LightAndShadow",
      "XX-Epilogue",
    ],
  },
  FE13: {
    Main: [
      "00-InvisibleTies",
      "00-TheVergeOfHistory",
      "01-UnwelcomeChange",
      "02-Shepherds",
      "03-WarriorRealm",
      "04-TwoFalchions",
      "05-TheExaltAndTheKing",
      "06-Forseer",
      "07-Incursion",
      "08-TheGrimleal",
      "09-Emmeryn",
      "10-Renewal",
      "11-MadKingGangrel",
      "12-TheSeacomers",
      "13-OfSacredBlood",
      "14-FlamesOnTheBlue",
      "15-SmolderingResistance",
      "16-Naga'sVoice",
      "17-InexorableDeath",
      "18-SiblingBlades",
      "19-TheConqueror",
      "20-TheSwordOrTheKnee",
      "21-FiveGemstones",
      "22-AnIllPresage",
      "23-InvisibleTies",
      "24-Awakening",
      "25-ToSlayAGod",
      "26-Grima",
    ],
    Paralogues: [
      "P01-SickleToSword",
      "P02-TheSecretSeller",
      "P03-AStrangledPeace",
      "P04-AnnaTheMerchant",
      "P05-ScionOfLegend",
      "P06-AManForFlowers",
      "P07-NobleLineage",
      "P08-ADuelDisgraced",
      "P09-WingsOfJustice",
      "P10-Ambivalence",
      "P11-TwinWyverns",
      "P12-DisownedByTime",
      "P13-RivalBands",
      "P14-ShadowInTheSands",
      "P15-AShotFromTheDark",
      "P16-DaughterToDragons",
      "P17-TheThreatOfSilence",
    ],
  },
  FE14: {
    Main: [
      "00-TiesThatBind",
      "01-Nohr",
      "02-GiftOfGanglari",
      "03-JourneyBegins",
      "04-Hoshido",
      "05-Mother",
      "06-ThePathIsYours",
      "06B-EmbraceTheDark",
      "06B-InTheWhiteLight",
      "07B-AVowUpheld",
      "07C-ADragon'sDegree",
      "08B-FierceWinds",
      "08C-ColdReception",
      "09B-LandOfGods",
      "09C-AnotherTrial",
      "10B-NinjaVillage",
      "10C-UnhappyReunion",
      "11B-ToTheSea",
      "11C-RainbowSage",
      "12B-DarkReunion",
      "12C-BitterIntrigue",
      "13B-AnotherHope",
      "13C-Uprising",
      "14B-LightScatters",
      "14C-VoiceOfParadise",
      "15B-WolfskinPeak",
      "15C-TheBlackPillar",
      "16B-PleasurePalace",
      "16C-Invasion",
      "17B-LostInTheIce",
      "17C-DenOfBetrayal",
      "18B-Leo",
      "18C-BlackAndWhite",
      "19B-RainbowSage",
      "19C-KitsuneLair",
      "20B-FortDragonfall",
      "20C-WindsOfChange",
      "21B-BurningFalls",
      "21C-EternalStairway",
      "22B-HiddenCapital",
      "22C-Sakura",
      "23B-Camilla",
      "23C-Possessed",
      "24B-TearsOfADragon",
      "24C-Hinoka",
      "25B-TraitorRevealed",
      "25C-Ryoma",
      "26B-Xander",
      "26C-Treason",
      "27B-KingGaron",
      "27C-TheEmptyKing",
      "28B-Endgame:DawnBreaks",
      "28C-Endgame:NightBreaksThrough",
    ],
    Paralogues: [
      "P01-TragicStart",
      "P02-DragonBlood",
      "P03-SurpriseDuet",
      "P04-FightOrFlight",
      "P05-BoldApproach",
      "P06-HerbalRemedy",
      "P07-FatherAndLiege",
      "P08-AGreatHunt",
      "P09-SaizoVsSaizo",
      "P10-HunterAndPrey",
      "P11-ALongGrudge",
      "P12-SweetDreams",
      "P13-TrulyTalented",
      "P14-AfterTheEnd",
      "P15-HiddenBravery",
      "P16-Abducted",
      "P17-TwoDefenders",
      "P18-NuttyFamily",
      "P19-GreatHeroism",
      "P20-UltimatePower",
      "P21-BrightSmile",
      "P22-AbruptClash",
    ],
  },
  FE15: {
    Main: [
      "00-Prologue",
      "01-Zofia'sCall",
      "02-ThePilgrimage",
      "03-WarOfDeliveranceAlm",
      "03-WarOfDeliveranceCelica",
      "03-WarOfDeliverancePrologue",
      "04-LandOfSorrowAlm",
      "04-LandOfSorrowCelica",
      "05-TogetherToTheEnd",
    ],
  },
  FE16: {
    AzureMoon: [
      "13-ReunionAtDawn",
      "14-TheDelusionalPrince",
      "15-ValleyOfTorment",
      "16-TheRoseColoredRiver",
      "17-BloodOfTheEagleAndLion",
      "18-TheKing'sTriumphantReturn",
      "19-TheGoldenDeer'sPlea",
      "20-TheImpregnableFortress",
      "21-OurChosenPaths",
      "22-OathOfTheDagger",
    ],
    CrimsonFlower: [
      "12-OutsetOfAPowerStruggle",
      "13-BeyondEscape",
      "14-TheMasterTactician",
      "15-TempestOfSwordsAndShields",
      "16-LadyOfDeceit",
      "17-FieldOfRevenge",
      "18-ToTheEndOfADream",
    ],
    Paralogues: [
      "P01-SwordAndShieldOfSeiros",
      "P02-FallingShortOfHeaven",
      "P03-DividingTheWorld",
      "P04-WarForTheWeak",
      "P05-RumoredNuptials",
      "P06-TrueChivalry",
      "P07-AnOceanView",
      "P08-OilAndWater",
      "P09-DeathToll",
      "P10-LandOfTheGoldenDeer",
      "P11-TalesOfTheRedCanyon",
      "P12-TheForgotten",
      "P13-WeathervanesOfFodlan",
      "P14-ForeignLandAndSky",
      "P15-ForgottenHero",
      "P15-LegendOfTheLake",
      "P16-TheFaceBeneath",
      "P17-Retribution",
      "P18-EternalGuardian",
      "P19-Insurmountable",
      "P20-DarknessBeneathTheEarth",
      "P21-TheSilverMaiden",
      "P22-TheSleepingSandLegend",
    ],
    SilverSnow: [
      "13-ReunionAtDawn",
      "14-AKingWithoutAKingdom",
      "15-ValleyOfTorment",
      "16-TheRoseColoredRiver",
      "17-TheImpregnableFortress",
      "18-TheChaosOfWar",
      "19-ConclusionOfTheCrossingRoads",
      "20-TheCityWithoutLight",
      "21-FollowingADream",
    ],
    VerdantWind: [
      "13-ReunionAtDawn",
      "14-TheAllianceLeader'sAmbitions",
      "15-ValleyOfTorment",
      "16-TheRoseColoredRiver",
      "17-BloodOfTheEagleAndLion",
      "18-TheGoldenScheme",
      "19-TheChaosOfWar",
      "20-ConclusionOfTheCrossingRoads",
      "21-TheCityWithoutLight",
      "22-Fodlan'sNewDream",
    ],
    WhiteClouds: [
      "00-AnInevitableEncounter",
      "01-ThreeHouses",
      "02-FamiliarScenery",
      "03-MutinyInTheMist",
      "04-TheGoddess'sRiteOfRebirth",
      "05-TowerOfBlackWinds",
      "06-RumorsOfAReaper",
      "07-FieldOfTheEagleAndLion",
      "08-TheFlameInTheDarkness",
      "09-TheCauseOfSorrow",
      "10-WhereTheGoddessDwells",
      "11-ThroneOfKnowledge",
      "12-ToWar",
    ],
  },
};

export const getRoutesFromGame = (game) => {
  return Object.keys(chapData[game]);
};

export const getChapters = (game, route) => {
    return chapData[game][route]
}