import fe1 from "../assets/characters/fe1.png";
import fe2 from "../assets/characters/fe2.png";
import fe3 from "../assets/characters/fe3.png";
import fe4 from "../assets/characters/fe4.png";
import fe5 from "../assets/characters/fe5.png";
import fe6 from "../assets/characters/fe6.png";
import fe7 from "../assets/characters/fe7.png";
import fe8 from "../assets/characters/fe8.png";
import fe9 from "../assets/characters/fe9.png";
import fe10 from "../assets/characters/fe10.png";
import fe11 from "../assets/characters/fe11.png";
import fe12 from "../assets/characters/fe12.png";
import fe13 from "../assets/characters/fe13.png";
import fe14 from "../assets/characters/fe14.png";
import fe15 from "../assets/characters/fe15.png";
import fe16 from "../assets/characters/fe16.png";

export const characterBars = [
  fe1,
  fe2,
  fe3,
  fe4,
  fe5,
  fe6,
  fe7,
  fe8,
  fe9,
  fe10,
  fe11,
  fe12,
  fe13,
  fe14,
  fe15,
  fe16,
];
